.slide-drawer{
    height: 100%;
    background: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0px;
    left: 0;
    width: 20em;
    max-width: 400px;
    z-index: 200;
    overflow-y: auto;
    /*transition: all .5s;*/
}

.slide-drawer ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height : 60px;
    margin : 0.5em auto;
}

.slide-drawer li{
    box-shadow: 6px 0 #025a2ada inset;
    margin-bottom: 5px;
    padding-left: 1.5em;
    margin-right: 0;
    transition: all .3s;
    color: white;
}

.slide-drawer .Children:hover {
    box-shadow: 20em 0 /*#0053b148*/  #025a2ada inset;
    color: white;
}

.slide-drawer a{
    color:#025a2ada;
    display: block;
    padding-left: 3em;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    transition: all .5s;
}

.slide-drawer a:hover,
.slide-drawer a:active{
    color: white;
}

.slide-drawer .aSubMenu:hover{
    color: #4D80E4;
}

.slide-drawer .SubMenu .Children{
    display: none;
}

.slide-drawer .SubMenu:hover .Children{
    display: block;
}

.slide-drawer .SubMenu:hover,
.slide-drawer .SubMenu:hover a,
.slide-drawer .SubMenu:hover ul li{
    background: #4D80E4;
    color: white;
}

.slide-drawer .SubMenu ul li:hover{
    box-shadow: 6px 0 white inset;
    transition: all .3s;
    color: #4D80E4;
}

